import { ApiListOfLoadsAllFilteredGetRequest, ApiListOfLoadsGetRequest, ListOfLoads, ListOfLoadsApi } from '../../generated-api';
import { createEntitySlice, EntityState, PageResponse } from '../../shared';
import { createAction, createAsyncThunk, isFulfilled } from '@reduxjs/toolkit';
import paginationApiFactory from '../../shared/util/paginationApiFactory';
import { apiFactory } from '../../shared';

const defaultValue: Readonly<ListOfLoads> = { };

const initialState: EntityState<ListOfLoads> = {
  loading: false,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
}

export const getListOfLoadsPage = createAsyncThunk(
  "ListOfLoads/fetch_entity_page",
  async (queryParams : ApiListOfLoadsGetRequest) => {
    let totalCount = 0;
    const api = paginationApiFactory(ListOfLoadsApi, total => totalCount = total);
    const entities = await api.apiListOfLoadsGet(queryParams);
    return { entities, totalCount } as PageResponse<ListOfLoads>;
  }
);

export const getListOfLoadsAllFiltered = createAsyncThunk(
  "ListOfLoads/fetch_all_filtered_entities",
  async (queryParams : ApiListOfLoadsAllFilteredGetRequest) => {
    const entities = await apiFactory(ListOfLoadsApi).apiListOfLoadsAllFilteredGet(queryParams);
    return { entities };
  }
);

export const updateEntity = createAction(
  'ListOfLoads/update_entity',
   (entity: ListOfLoads) => {
    return { payload: entity };
  }
);

export const setEntitiesFieldsWithAction = createAction(
  'ListOfLoads/update_multitple_entities',
  (entities: ListOfLoads[]) => {
    return { payload: entities };
  }
);

export const ListOfLoadsSlice = createEntitySlice({
  name: 'ListOfLoads',
  initialState,
  extraReducers(builder) {
    builder.addCase(setEntitiesFieldsWithAction, (state, action) => {
      const entities = action.payload;
      const newEntities = state.entities.map(x => {
        const newEntity = entities.find(y => y.id === x.id); 
        return newEntity || x;
      });
      
      return {
        ...state,
        entities: newEntities,
      };
    }).addCase(updateEntity, (state, action) => {
      const entity = action.payload;
      const listEntity = state.entities.find(x => x.id === entity.id);
      if (!listEntity) return;

      return {
        ...state,
        entities: state.entities.map(x => x === listEntity ? entity : x),
      }
    }).addMatcher(isFulfilled(getListOfLoadsPage), (state, action) => {
      const data = action.payload;

      return {
        ...state,
        loading: false,
        entities: data.entities,
        totalItems: data.totalCount,
      }
    }).addMatcher(isFulfilled(getListOfLoadsAllFiltered), (state, action) => {
      const data = action.payload;

      return {
        ...state,
        loading: false,
        entities: data.entities,
      }
    })
  }
});

export default ListOfLoadsSlice.reducer;