/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ECompany,
  EStatusHR,
  Owner,
} from '../models';
import {
    ECompanyFromJSON,
    ECompanyToJSON,
    EStatusHRFromJSON,
    EStatusHRToJSON,
    OwnerFromJSON,
    OwnerToJSON,
} from '../models';

export interface ApiOwnersAllGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiOwnersFullNameCompanyGetRequest {
    company?: ECompany;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiOwnersGetRequest {
    active?: boolean;
    id?: number;
    sylectusId?: number;
    companyName?: string;
    name?: string;
    address?: string;
    email?: string;
    phones?: string;
    taxpayerId?: number;
    statusHR?: EStatusHR;
    phone?: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiOwnersIdDeleteRequest {
    id: number;
}

export interface ApiOwnersIdGetRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiOwnersIdPutRequest {
    id: number;
    owner?: Owner;
}

export interface ApiOwnersPostRequest {
    owner?: Owner;
}

/**
 * 
 */
export class OwnersApi extends runtime.BaseAPI {

    /**
     */
    async apiOwnersAllGetRaw(requestParameters: ApiOwnersAllGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Owner>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/owners/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OwnerFromJSON));
    }

    /**
     */
    async apiOwnersAllGet(requestParameters: ApiOwnersAllGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Owner>> {
        const response = await this.apiOwnersAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOwnersFullNameCompanyGetRaw(requestParameters: ApiOwnersFullNameCompanyGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Owner>>> {
        const queryParameters: any = {};

        if (requestParameters.company !== undefined) {
            queryParameters['company'] = requestParameters.company;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/owners/fullNameCompany`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OwnerFromJSON));
    }

    /**
     */
    async apiOwnersFullNameCompanyGet(requestParameters: ApiOwnersFullNameCompanyGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Owner>> {
        const response = await this.apiOwnersFullNameCompanyGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOwnersGetRaw(requestParameters: ApiOwnersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Owner>>> {
        const queryParameters: any = {};

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.sylectusId !== undefined) {
            queryParameters['sylectusId'] = requestParameters.sylectusId;
        }

        if (requestParameters.companyName !== undefined) {
            queryParameters['companyName'] = requestParameters.companyName;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.address !== undefined) {
            queryParameters['address'] = requestParameters.address;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.phones !== undefined) {
            queryParameters['phones'] = requestParameters.phones;
        }

        if (requestParameters.taxpayerId !== undefined) {
            queryParameters['taxpayerId'] = requestParameters.taxpayerId;
        }

        if (requestParameters.statusHR !== undefined) {
            queryParameters['statusHR'] = requestParameters.statusHR;
        }

        if (requestParameters.phone !== undefined) {
            queryParameters['phone'] = requestParameters.phone;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/owners`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OwnerFromJSON));
    }

    /**
     */
    async apiOwnersGet(requestParameters: ApiOwnersGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Owner>> {
        const response = await this.apiOwnersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOwnersIdDeleteRaw(requestParameters: ApiOwnersIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOwnersIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/owners/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiOwnersIdDelete(requestParameters: ApiOwnersIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiOwnersIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiOwnersIdGetRaw(requestParameters: ApiOwnersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Owner>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOwnersIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/owners/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OwnerFromJSON(jsonValue));
    }

    /**
     */
    async apiOwnersIdGet(requestParameters: ApiOwnersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Owner> {
        const response = await this.apiOwnersIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiOwnersIdPutRaw(requestParameters: ApiOwnersIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOwnersIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/owners/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OwnerToJSON(requestParameters.owner),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiOwnersIdPut(requestParameters: ApiOwnersIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiOwnersIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiOwnersPostRaw(requestParameters: ApiOwnersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Owner>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/owners`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OwnerToJSON(requestParameters.owner),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OwnerFromJSON(jsonValue));
    }

    /**
     */
    async apiOwnersPost(requestParameters: ApiOwnersPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Owner> {
        const response = await this.apiOwnersPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
