/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Bid } from './Bid';
import {
    BidFromJSON,
    BidFromJSONTyped,
    BidToJSON,
} from './Bid';

/**
 * 
 * @export
 * @interface EmailToBroker
 */
export interface EmailToBroker {
    /**
     * 
     * @type {string}
     * @memberof EmailToBroker
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EmailToBroker
     */
    bidId?: number | null;
    /**
     * 
     * @type {Bid}
     * @memberof EmailToBroker
     */
    bid?: Bid;
    /**
     * 
     * @type {number}
     * @memberof EmailToBroker
     */
    id?: number;
}

/**
 * Check if a given object implements the EmailToBroker interface.
 */
export function instanceOfEmailToBroker(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmailToBrokerFromJSON(json: any): EmailToBroker {
    return EmailToBrokerFromJSONTyped(json, false);
}

export function EmailToBrokerFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailToBroker {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'bidId': !exists(json, 'bidId') ? undefined : json['bidId'],
        'bid': !exists(json, 'bid') ? undefined : BidFromJSON(json['bid']),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function EmailToBrokerToJSON(value?: EmailToBroker | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'bidId': value.bidId,
        'bid': BidToJSON(value.bid),
        'id': value.id,
    };
}

