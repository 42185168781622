/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Driver } from './Driver';
import {
    DriverFromJSON,
    DriverFromJSONTyped,
    DriverToJSON,
} from './Driver';
import type { DriverBid } from './DriverBid';
import {
    DriverBidFromJSON,
    DriverBidFromJSONTyped,
    DriverBidToJSON,
} from './DriverBid';

/**
 * 
 * @export
 * @interface DriverBidsResponse
 */
export interface DriverBidsResponse {
    /**
     * 
     * @type {Driver}
     * @memberof DriverBidsResponse
     */
    driver?: Driver;
    /**
     * 
     * @type {Array<DriverBid>}
     * @memberof DriverBidsResponse
     */
    driverBids?: Array<DriverBid> | null;
}

/**
 * Check if a given object implements the DriverBidsResponse interface.
 */
export function instanceOfDriverBidsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DriverBidsResponseFromJSON(json: any): DriverBidsResponse {
    return DriverBidsResponseFromJSONTyped(json, false);
}

export function DriverBidsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DriverBidsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'driver': !exists(json, 'driver') ? undefined : DriverFromJSON(json['driver']),
        'driverBids': !exists(json, 'driverBids') ? undefined : (json['driverBids'] === null ? null : (json['driverBids'] as Array<any>).map(DriverBidFromJSON)),
    };
}

export function DriverBidsResponseToJSON(value?: DriverBidsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'driver': DriverToJSON(value.driver),
        'driverBids': value.driverBids === undefined ? undefined : (value.driverBids === null ? null : (value.driverBids as Array<any>).map(DriverBidToJSON)),
    };
}

