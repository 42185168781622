/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Bid } from './Bid';
import {
    BidFromJSON,
    BidFromJSONTyped,
    BidToJSON,
} from './Bid';
import type { Brokerage } from './Brokerage';
import {
    BrokerageFromJSON,
    BrokerageFromJSONTyped,
    BrokerageToJSON,
} from './Brokerage';
import type { Driver } from './Driver';
import {
    DriverFromJSON,
    DriverFromJSONTyped,
    DriverToJSON,
} from './Driver';
import type { ECompany } from './ECompany';
import {
    ECompanyFromJSON,
    ECompanyFromJSONTyped,
    ECompanyToJSON,
} from './ECompany';
import type { EDispatchStatus } from './EDispatchStatus';
import {
    EDispatchStatusFromJSON,
    EDispatchStatusFromJSONTyped,
    EDispatchStatusToJSON,
} from './EDispatchStatus';
import type { ETrackingOption } from './ETrackingOption';
import {
    ETrackingOptionFromJSON,
    ETrackingOptionFromJSONTyped,
    ETrackingOptionToJSON,
} from './ETrackingOption';
import type { ETrackingStatus } from './ETrackingStatus';
import {
    ETrackingStatusFromJSON,
    ETrackingStatusFromJSONTyped,
    ETrackingStatusToJSON,
} from './ETrackingStatus';
import type { ListOfLoads } from './ListOfLoads';
import {
    ListOfLoadsFromJSON,
    ListOfLoadsFromJSONTyped,
    ListOfLoadsToJSON,
} from './ListOfLoads';
import type { LogisticsFile } from './LogisticsFile';
import {
    LogisticsFileFromJSON,
    LogisticsFileFromJSONTyped,
    LogisticsFileToJSON,
} from './LogisticsFile';
import type { Owner } from './Owner';
import {
    OwnerFromJSON,
    OwnerFromJSONTyped,
    OwnerToJSON,
} from './Owner';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Tracking
 */
export interface Tracking {
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    number?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Tracking
     */
    createTime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    pickUpAtState?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    pickUpAtCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    deliverToState?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    deliverToCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    mail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    period?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Tracking
     */
    lastTime?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Tracking
     */
    nextTime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    trackerComment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    linkTracking?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Tracking
     */
    freight?: boolean | null;
    /**
     * 
     * @type {ETrackingOption}
     * @memberof Tracking
     */
    option?: ETrackingOption;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    dispatcherComment?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Tracking
     */
    mp?: boolean | null;
    /**
     * 
     * @type {ETrackingStatus}
     * @memberof Tracking
     */
    status?: ETrackingStatus;
    /**
     * 
     * @type {LogisticsFile}
     * @memberof Tracking
     */
    rateConfirmation?: LogisticsFile;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Tracking
     */
    createdDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    lastModifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Tracking
     */
    lastModifiedDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Tracking
     */
    driverId?: number | null;
    /**
     * 
     * @type {Driver}
     * @memberof Tracking
     */
    driver?: Driver;
    /**
     * 
     * @type {number}
     * @memberof Tracking
     */
    ownerId?: number | null;
    /**
     * 
     * @type {Owner}
     * @memberof Tracking
     */
    owner?: Owner;
    /**
     * 
     * @type {number}
     * @memberof Tracking
     */
    dispatcherId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof Tracking
     */
    dispatcher?: User;
    /**
     * 
     * @type {number}
     * @memberof Tracking
     */
    brokerageId?: number | null;
    /**
     * 
     * @type {Brokerage}
     * @memberof Tracking
     */
    brokerage?: Brokerage;
    /**
     * 
     * @type {number}
     * @memberof Tracking
     */
    bidId?: number | null;
    /**
     * 
     * @type {Bid}
     * @memberof Tracking
     */
    bid?: Bid;
    /**
     * 
     * @type {EDispatchStatus}
     * @memberof Tracking
     */
    dispStatus?: EDispatchStatus;
    /**
     * 
     * @type {Array<ListOfLoads>}
     * @memberof Tracking
     */
    listsOfLoads?: Array<ListOfLoads> | null;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    commentForDriver?: string | null;
    /**
     * 
     * @type {ECompany}
     * @memberof Tracking
     */
    company?: ECompany;
    /**
     * 
     * @type {ECompany}
     * @memberof Tracking
     */
    lastModifyByCompany?: ECompany;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    typePrevUpdate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    typeNextUpdate?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Tracking
     */
    timeToDelivery?: Date | null;
    /**
     * 
     * @type {User}
     * @memberof Tracking
     */
    tracker?: User;
    /**
     * 
     * @type {string}
     * @memberof Tracking
     */
    nameOfMailThread?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Tracking
     */
    id?: number;
}

/**
 * Check if a given object implements the Tracking interface.
 */
export function instanceOfTracking(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TrackingFromJSON(json: any): Tracking {
    return TrackingFromJSONTyped(json, false);
}

export function TrackingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tracking {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': !exists(json, 'number') ? undefined : json['number'],
        'createTime': !exists(json, 'createTime') ? undefined : (json['createTime'] === null ? null : new Date(json['createTime'])),
        'pickUpAtState': !exists(json, 'pickUpAtState') ? undefined : json['pickUpAtState'],
        'pickUpAtCity': !exists(json, 'pickUpAtCity') ? undefined : json['pickUpAtCity'],
        'deliverToState': !exists(json, 'deliverToState') ? undefined : json['deliverToState'],
        'deliverToCity': !exists(json, 'deliverToCity') ? undefined : json['deliverToCity'],
        'mail': !exists(json, 'mail') ? undefined : json['mail'],
        'period': !exists(json, 'period') ? undefined : json['period'],
        'lastTime': !exists(json, 'lastTime') ? undefined : (json['lastTime'] === null ? null : new Date(json['lastTime'])),
        'nextTime': !exists(json, 'nextTime') ? undefined : (json['nextTime'] === null ? null : new Date(json['nextTime'])),
        'trackerComment': !exists(json, 'trackerComment') ? undefined : json['trackerComment'],
        'linkTracking': !exists(json, 'linkTracking') ? undefined : json['linkTracking'],
        'freight': !exists(json, 'freight') ? undefined : json['freight'],
        'option': !exists(json, 'option') ? undefined : ETrackingOptionFromJSON(json['option']),
        'dispatcherComment': !exists(json, 'dispatcherComment') ? undefined : json['dispatcherComment'],
        'mp': !exists(json, 'mp') ? undefined : json['mp'],
        'status': !exists(json, 'status') ? undefined : ETrackingStatusFromJSON(json['status']),
        'rateConfirmation': !exists(json, 'rateConfirmation') ? undefined : LogisticsFileFromJSON(json['rateConfirmation']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (json['createdDate'] === null ? null : new Date(json['createdDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (json['lastModifiedDate'] === null ? null : new Date(json['lastModifiedDate'])),
        'driverId': !exists(json, 'driverId') ? undefined : json['driverId'],
        'driver': !exists(json, 'driver') ? undefined : DriverFromJSON(json['driver']),
        'ownerId': !exists(json, 'ownerId') ? undefined : json['ownerId'],
        'owner': !exists(json, 'owner') ? undefined : OwnerFromJSON(json['owner']),
        'dispatcherId': !exists(json, 'dispatcherId') ? undefined : json['dispatcherId'],
        'dispatcher': !exists(json, 'dispatcher') ? undefined : UserFromJSON(json['dispatcher']),
        'brokerageId': !exists(json, 'brokerageId') ? undefined : json['brokerageId'],
        'brokerage': !exists(json, 'brokerage') ? undefined : BrokerageFromJSON(json['brokerage']),
        'bidId': !exists(json, 'bidId') ? undefined : json['bidId'],
        'bid': !exists(json, 'bid') ? undefined : BidFromJSON(json['bid']),
        'dispStatus': !exists(json, 'dispStatus') ? undefined : EDispatchStatusFromJSON(json['dispStatus']),
        'listsOfLoads': !exists(json, 'listsOfLoads') ? undefined : (json['listsOfLoads'] === null ? null : (json['listsOfLoads'] as Array<any>).map(ListOfLoadsFromJSON)),
        'commentForDriver': !exists(json, 'commentForDriver') ? undefined : json['commentForDriver'],
        'company': !exists(json, 'company') ? undefined : ECompanyFromJSON(json['company']),
        'lastModifyByCompany': !exists(json, 'lastModifyByCompany') ? undefined : ECompanyFromJSON(json['lastModifyByCompany']),
        'typePrevUpdate': !exists(json, 'typePrevUpdate') ? undefined : json['typePrevUpdate'],
        'typeNextUpdate': !exists(json, 'typeNextUpdate') ? undefined : json['typeNextUpdate'],
        'timeToDelivery': !exists(json, 'timeToDelivery') ? undefined : (json['timeToDelivery'] === null ? null : new Date(json['timeToDelivery'])),
        'tracker': !exists(json, 'tracker') ? undefined : UserFromJSON(json['tracker']),
        'nameOfMailThread': !exists(json, 'nameOfMailThread') ? undefined : json['nameOfMailThread'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function TrackingToJSON(value?: Tracking | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'createTime': value.createTime === undefined ? undefined : (value.createTime === null ? null : value.createTime.toISOString()),
        'pickUpAtState': value.pickUpAtState,
        'pickUpAtCity': value.pickUpAtCity,
        'deliverToState': value.deliverToState,
        'deliverToCity': value.deliverToCity,
        'mail': value.mail,
        'period': value.period,
        'lastTime': value.lastTime === undefined ? undefined : (value.lastTime === null ? null : value.lastTime.toISOString()),
        'nextTime': value.nextTime === undefined ? undefined : (value.nextTime === null ? null : value.nextTime.toISOString()),
        'trackerComment': value.trackerComment,
        'linkTracking': value.linkTracking,
        'freight': value.freight,
        'option': ETrackingOptionToJSON(value.option),
        'dispatcherComment': value.dispatcherComment,
        'mp': value.mp,
        'status': ETrackingStatusToJSON(value.status),
        'rateConfirmation': LogisticsFileToJSON(value.rateConfirmation),
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate === null ? null : value.createdDate.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate === null ? null : value.lastModifiedDate.toISOString()),
        'driverId': value.driverId,
        'driver': DriverToJSON(value.driver),
        'ownerId': value.ownerId,
        'owner': OwnerToJSON(value.owner),
        'dispatcherId': value.dispatcherId,
        'dispatcher': UserToJSON(value.dispatcher),
        'brokerageId': value.brokerageId,
        'brokerage': BrokerageToJSON(value.brokerage),
        'bidId': value.bidId,
        'bid': BidToJSON(value.bid),
        'dispStatus': EDispatchStatusToJSON(value.dispStatus),
        'listsOfLoads': value.listsOfLoads === undefined ? undefined : (value.listsOfLoads === null ? null : (value.listsOfLoads as Array<any>).map(ListOfLoadsToJSON)),
        'commentForDriver': value.commentForDriver,
        'company': ECompanyToJSON(value.company),
        'lastModifyByCompany': ECompanyToJSON(value.lastModifyByCompany),
        'typePrevUpdate': value.typePrevUpdate,
        'typeNextUpdate': value.typeNextUpdate,
        'timeToDelivery': value.timeToDelivery === undefined ? undefined : (value.timeToDelivery === null ? null : value.timeToDelivery.toISOString()),
        'tracker': UserToJSON(value.tracker),
        'nameOfMailThread': value.nameOfMailThread,
        'id': value.id,
    };
}

