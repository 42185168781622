/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Driver } from './Driver';
import {
    DriverFromJSON,
    DriverFromJSONTyped,
    DriverToJSON,
} from './Driver';
import type { Vehicle } from './Vehicle';
import {
    VehicleFromJSON,
    VehicleFromJSONTyped,
    VehicleToJSON,
} from './Vehicle';

/**
 * 
 * @export
 * @interface VehicleForOffer
 */
export interface VehicleForOffer {
    /**
     * 
     * @type {Vehicle}
     * @memberof VehicleForOffer
     */
    vehicle?: Vehicle;
    /**
     * 
     * @type {number}
     * @memberof VehicleForOffer
     */
    vehicleMiles?: number;
    /**
     * 
     * @type {Driver}
     * @memberof VehicleForOffer
     */
    driver?: Driver;
}

/**
 * Check if a given object implements the VehicleForOffer interface.
 */
export function instanceOfVehicleForOffer(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VehicleForOfferFromJSON(json: any): VehicleForOffer {
    return VehicleForOfferFromJSONTyped(json, false);
}

export function VehicleForOfferFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleForOffer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicle': !exists(json, 'vehicle') ? undefined : VehicleFromJSON(json['vehicle']),
        'vehicleMiles': !exists(json, 'vehicleMiles') ? undefined : json['vehicleMiles'],
        'driver': !exists(json, 'driver') ? undefined : DriverFromJSON(json['driver']),
    };
}

export function VehicleForOfferToJSON(value?: VehicleForOffer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicle': VehicleToJSON(value.vehicle),
        'vehicleMiles': value.vehicleMiles,
        'driver': DriverToJSON(value.driver),
    };
}

