/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BidReply,
} from '../models';
import {
    BidReplyFromJSON,
    BidReplyToJSON,
} from '../models';

export interface ApiBidRepliesGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiBidRepliesIdDeleteRequest {
    id: number;
}

export interface ApiBidRepliesIdGetRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiBidRepliesIdPutRequest {
    id: number;
    bidReply?: BidReply;
}

export interface ApiBidRepliesPostRequest {
    bidReply?: BidReply;
}

/**
 * 
 */
export class BidRepliesApi extends runtime.BaseAPI {

    /**
     */
    async apiBidRepliesGetRaw(requestParameters: ApiBidRepliesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BidReply>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bid-replies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BidReplyFromJSON));
    }

    /**
     */
    async apiBidRepliesGet(requestParameters: ApiBidRepliesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BidReply>> {
        const response = await this.apiBidRepliesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBidRepliesIdDeleteRaw(requestParameters: ApiBidRepliesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiBidRepliesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bid-replies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiBidRepliesIdDelete(requestParameters: ApiBidRepliesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiBidRepliesIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiBidRepliesIdGetRaw(requestParameters: ApiBidRepliesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BidReply>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiBidRepliesIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bid-replies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BidReplyFromJSON(jsonValue));
    }

    /**
     */
    async apiBidRepliesIdGet(requestParameters: ApiBidRepliesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BidReply> {
        const response = await this.apiBidRepliesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiBidRepliesIdPutRaw(requestParameters: ApiBidRepliesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiBidRepliesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bid-replies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BidReplyToJSON(requestParameters.bidReply),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiBidRepliesIdPut(requestParameters: ApiBidRepliesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiBidRepliesIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiBidRepliesPostRaw(requestParameters: ApiBidRepliesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BidReply>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/bid-replies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BidReplyToJSON(requestParameters.bidReply),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BidReplyFromJSON(jsonValue));
    }

    /**
     */
    async apiBidRepliesPost(requestParameters: ApiBidRepliesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BidReply> {
        const response = await this.apiBidRepliesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
