import { LoadedOrder } from '../../generated-api';
import { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { expireDriverBid } from './LoadedOrdersSlice';

const tenMinutes = 10 * 60 * 1000;
const oneSecond = 2 * 1000;

function useRecentDriverBids() {
  const dispatch = useAppDispatch();
  const loadedOrders = useAppSelector(state => state.loadedOrder.entities);
  const loadedOrdersRef = useRef<LoadedOrder[]>(loadedOrders as LoadedOrder[]);

  useEffect(() => {
    loadedOrdersRef.current = loadedOrders as LoadedOrder[];
  }, [loadedOrders]);

  useEffect(() => {
    const interval = window.setInterval(() => {
      dispatch(expireDriverBid());
    }, oneSecond);

    return () => {
      window.clearInterval(interval);
    }
  }, [dispatch]);

}

export function calcTimeLeft(loadedOrder: LoadedOrder) {
  if (!loadedOrder.lastDriverBid || loadedOrder.bids?.length)
    return 0;

  const result = tenMinutes - dayjs().diff(loadedOrder.lastDriverBid, 'millisecond');
  return result;
}

export default useRecentDriverBids;