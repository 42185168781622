import React, { lazy, Suspense } from 'react';
import { Route, Routes, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { history } from '../shared';
import LightweightSpinner from '../components/LightweightSpinner';

const ProtectedApp = lazy(() => import('../app/private/ProtectedApp'));
const DashboardPage = lazy(() => import('../features/dashboard/DashboardPage'));
const LoadedOrderList = lazy(() => import('../features/loaded-orders/LoadedOrderList'));
const BidList = lazy(() => import('../features/bids/BidList'));
const Vehicles = lazy(() => import('../features/vehicles/VehiclesPage'));
const Settings = lazy(() => import('../features/settings/SettingsPage'));
const Login = lazy(() => import('../features/login/Login'));
const Experiments = lazy(() => import('../features/experimental/Experiments'));
const ListOfLoadsList = lazy(() => import('../features/list-of-loads/ListOfLoadsList'));
const TrackingPage = lazy(() => import('../features/tracking/TrackingPage'));
const RequestList = lazy(() => import('../features/hr/request-list/RequestList'));
const RequestEdit = lazy(() => import('../features/hr/request-edit/RequestEdit'));
const HRPage = lazy(() => import('../features/hr/HRPage'));
const Registration = lazy(() => import('../features/registration/Registration'));
const RequestEditPublic = lazy(() => import('../features/hr/request-edit-public/RequestEditPublic'));
const PublicApp = lazy(() => import('../app/PublicApp'));
const VehicleRoute = lazy(() => import('../features/map/public-tracking/VehicleRoute'));
const TrackingMulticompanyPage = lazy(() => import('../features/tracking/TrackingMulticompanyPage'));

function AppRoutes() {
  // init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  history.navigate = useNavigate();
  history.location = useLocation();

  return (
    <Suspense fallback={<LightweightSpinner />}>
      <Routes>
        <Route path="/" element={<PublicApp />}>
          {/* Public routes */}
          <Route path='referral' element={<Registration />} />
          <Route path='referral/:referralId' element={<Registration />} />
          <Route path='request/pub/:id' element={<RequestEditPublic />} />
          <Route path='login' element={<Login />} />
          <Route path='map/:id' element={<VehicleRoute />} />
          {/* Private routes */}
          <Route path='/' element={<ProtectedApp />}>
            <Route path='dashboard' element={<DashboardPage />} />
            <Route path='loaded-orders' element={<LoadedOrderList />} />
            <Route path='bids' element={<BidList />} />
            <Route path='tracking' element={<TrackingPage />} />
            <Route path='tracking/m' element={<TrackingMulticompanyPage />} />
            <Route path='vehicles' element={<Vehicles />} />
            <Route path='list-of-loads' element={<ListOfLoadsList />} />
            <Route path='request' element={<RequestList /> } />
            <Route path='request/new' element={<RequestEdit />} />
            <Route path='request/:id' element={<RequestEdit />} />
            <Route path='hr' element={<HRPage /> } />
            <Route path='settings' element={<Settings />} />
            <Route path='experimental' element={<Experiments />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}


export default AppRoutes;
