/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EGreetingMessageTarget = {
    None: 'None',
    Driver: 'Driver',
    Owner: 'Owner'
} as const;
export type EGreetingMessageTarget = typeof EGreetingMessageTarget[keyof typeof EGreetingMessageTarget];


export function EGreetingMessageTargetFromJSON(json: any): EGreetingMessageTarget {
    return EGreetingMessageTargetFromJSONTyped(json, false);
}

export function EGreetingMessageTargetFromJSONTyped(json: any, ignoreDiscriminator: boolean): EGreetingMessageTarget {
    return json as EGreetingMessageTarget;
}

export function EGreetingMessageTargetToJSON(value?: EGreetingMessageTarget | null): any {
    return value as any;
}

