/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DriverBid,
  DriverBidsResponse,
} from '../models';
import {
    DriverBidFromJSON,
    DriverBidToJSON,
    DriverBidsResponseFromJSON,
    DriverBidsResponseToJSON,
} from '../models';

export interface ApiMobileDriverBidsGetRequest {
    issuerId?: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiMobileDriverBidsPostRequest {
    driverBid?: DriverBid;
}

export interface ApiMobileDriverBidsV2GetRequest {
    issuerId?: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

/**
 * 
 */
export class DriverBidsApi extends runtime.BaseAPI {

    /**
     */
    async apiMobileDriverBidsGetRaw(requestParameters: ApiMobileDriverBidsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DriverBidsResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.issuerId !== undefined) {
            queryParameters['issuerId'] = requestParameters.issuerId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mobile/driverBids`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverBidsResponseFromJSON));
    }

    /**
     */
    async apiMobileDriverBidsGet(requestParameters: ApiMobileDriverBidsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DriverBidsResponse>> {
        const response = await this.apiMobileDriverBidsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiMobileDriverBidsPostRaw(requestParameters: ApiMobileDriverBidsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DriverBid>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mobile/driverBids`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DriverBidToJSON(requestParameters.driverBid),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DriverBidFromJSON(jsonValue));
    }

    /**
     */
    async apiMobileDriverBidsPost(requestParameters: ApiMobileDriverBidsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DriverBid> {
        const response = await this.apiMobileDriverBidsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiMobileDriverBidsV2GetRaw(requestParameters: ApiMobileDriverBidsV2GetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DriverBid>>> {
        const queryParameters: any = {};

        if (requestParameters.issuerId !== undefined) {
            queryParameters['issuerId'] = requestParameters.issuerId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mobile/driverBids/v2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DriverBidFromJSON));
    }

    /**
     */
    async apiMobileDriverBidsV2Get(requestParameters: ApiMobileDriverBidsV2GetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DriverBid>> {
        const response = await this.apiMobileDriverBidsV2GetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
