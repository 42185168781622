/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProfileInfoDto,
} from '../models';
import {
    ProfileInfoDtoFromJSON,
    ProfileInfoDtoToJSON,
} from '../models';

export interface ManagementInfoGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

/**
 * 
 */
export class ProfileInfoApi extends runtime.BaseAPI {

    /**
     */
    async managementInfoGetRaw(requestParameters: ManagementInfoGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/management/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async managementInfoGet(requestParameters: ManagementInfoGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileInfoDto> {
        const response = await this.managementInfoGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
