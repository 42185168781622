/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ELanguage = {
    None: 'None',
    English: 'English',
    Spanish: 'Spanish',
    Russian: 'Russian'
} as const;
export type ELanguage = typeof ELanguage[keyof typeof ELanguage];


export function ELanguageFromJSON(json: any): ELanguage {
    return ELanguageFromJSONTyped(json, false);
}

export function ELanguageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ELanguage {
    return json as ELanguage;
}

export function ELanguageToJSON(value?: ELanguage | null): any {
    return value as any;
}

