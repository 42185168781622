/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrackingAccess
 */
export interface TrackingAccess {
    /**
     * 
     * @type {boolean}
     * @memberof TrackingAccess
     */
    limitedFields?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TrackingAccess
     */
    create?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TrackingAccess
     */
    read?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TrackingAccess
     */
    update?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TrackingAccess
     */
    _delete?: boolean | null;
}

/**
 * Check if a given object implements the TrackingAccess interface.
 */
export function instanceOfTrackingAccess(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TrackingAccessFromJSON(json: any): TrackingAccess {
    return TrackingAccessFromJSONTyped(json, false);
}

export function TrackingAccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrackingAccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limitedFields': !exists(json, 'limitedFields') ? undefined : json['limitedFields'],
        'create': !exists(json, 'create') ? undefined : json['create'],
        'read': !exists(json, 'read') ? undefined : json['read'],
        'update': !exists(json, 'update') ? undefined : json['update'],
        '_delete': !exists(json, 'delete') ? undefined : json['delete'],
    };
}

export function TrackingAccessToJSON(value?: TrackingAccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'limitedFields': value.limitedFields,
        'create': value.create,
        'read': value.read,
        'update': value.update,
        'delete': value._delete,
    };
}

