/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EStatusHR,
  OfferDto,
  OfferResponse,
} from '../models';
import {
    EStatusHRFromJSON,
    EStatusHRToJSON,
    OfferDtoFromJSON,
    OfferDtoToJSON,
    OfferResponseFromJSON,
    OfferResponseToJSON,
} from '../models';

export interface ApiMobileLoadedOrdersGetRequest {
    userId?: string;
    statusHr?: EStatusHR;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiMobileLoadedOrdersV2GetRequest {
    userId?: string;
    statusHr?: EStatusHR;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

/**
 * 
 */
export class LoadedOrderApi extends runtime.BaseAPI {

    /**
     */
    async apiMobileLoadedOrdersGetRaw(requestParameters: ApiMobileLoadedOrdersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OfferResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.statusHr !== undefined) {
            queryParameters['statusHr'] = requestParameters.statusHr;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mobile/loadedOrders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OfferResponseFromJSON));
    }

    /**
     */
    async apiMobileLoadedOrdersGet(requestParameters: ApiMobileLoadedOrdersGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OfferResponse>> {
        const response = await this.apiMobileLoadedOrdersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiMobileLoadedOrdersV2GetRaw(requestParameters: ApiMobileLoadedOrdersV2GetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OfferDto>>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.statusHr !== undefined) {
            queryParameters['statusHr'] = requestParameters.statusHr;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mobile/loadedOrders/v2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OfferDtoFromJSON));
    }

    /**
     */
    async apiMobileLoadedOrdersV2Get(requestParameters: ApiMobileLoadedOrdersV2GetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OfferDto>> {
        const response = await this.apiMobileLoadedOrdersV2GetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
