/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Request,
} from '../models';
import {
    RequestFromJSON,
    RequestToJSON,
} from '../models';

export interface ApiPubRequestIdGetRequest {
    id: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiPubRequestIdPutRequest {
    id: string;
    request?: Request;
}

/**
 * 
 */
export class PublicRequestApi extends runtime.BaseAPI {

    /**
     */
    async apiPubRequestIdGetRaw(requestParameters: ApiPubRequestIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Request>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPubRequestIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/pub/request/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestFromJSON(jsonValue));
    }

    /**
     */
    async apiPubRequestIdGet(requestParameters: ApiPubRequestIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Request> {
        const response = await this.apiPubRequestIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPubRequestIdPutRaw(requestParameters: ApiPubRequestIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Request>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPubRequestIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/pub/request/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RequestToJSON(requestParameters.request),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestFromJSON(jsonValue));
    }

    /**
     */
    async apiPubRequestIdPut(requestParameters: ApiPubRequestIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Request> {
        const response = await this.apiPubRequestIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
