/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ViewedLoadedOrder,
} from '../models';
import {
    ViewedLoadedOrderFromJSON,
    ViewedLoadedOrderToJSON,
} from '../models';

export interface ApiViewedLoadedOrdersGetRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiViewedLoadedOrdersIdDeleteRequest {
    id: number;
}

export interface ApiViewedLoadedOrdersIdGetRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiViewedLoadedOrdersIdPutRequest {
    id: number;
    viewedLoadedOrder?: ViewedLoadedOrder;
}

export interface ApiViewedLoadedOrdersPostRequest {
    viewedLoadedOrder?: ViewedLoadedOrder;
}

/**
 * 
 */
export class ViewedLoadedOrdersApi extends runtime.BaseAPI {

    /**
     */
    async apiViewedLoadedOrdersGetRaw(requestParameters: ApiViewedLoadedOrdersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ViewedLoadedOrder>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/viewed-loaded-orders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ViewedLoadedOrderFromJSON));
    }

    /**
     */
    async apiViewedLoadedOrdersGet(requestParameters: ApiViewedLoadedOrdersGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ViewedLoadedOrder>> {
        const response = await this.apiViewedLoadedOrdersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiViewedLoadedOrdersIdDeleteRaw(requestParameters: ApiViewedLoadedOrdersIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiViewedLoadedOrdersIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/viewed-loaded-orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiViewedLoadedOrdersIdDelete(requestParameters: ApiViewedLoadedOrdersIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiViewedLoadedOrdersIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiViewedLoadedOrdersIdGetRaw(requestParameters: ApiViewedLoadedOrdersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiViewedLoadedOrdersIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/viewed-loaded-orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiViewedLoadedOrdersIdGet(requestParameters: ApiViewedLoadedOrdersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiViewedLoadedOrdersIdGetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiViewedLoadedOrdersIdPutRaw(requestParameters: ApiViewedLoadedOrdersIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiViewedLoadedOrdersIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/viewed-loaded-orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ViewedLoadedOrderToJSON(requestParameters.viewedLoadedOrder),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiViewedLoadedOrdersIdPut(requestParameters: ApiViewedLoadedOrdersIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiViewedLoadedOrdersIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiViewedLoadedOrdersPostRaw(requestParameters: ApiViewedLoadedOrdersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ViewedLoadedOrder>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/viewed-loaded-orders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ViewedLoadedOrderToJSON(requestParameters.viewedLoadedOrder),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ViewedLoadedOrderFromJSON(jsonValue));
    }

    /**
     */
    async apiViewedLoadedOrdersPost(requestParameters: ApiViewedLoadedOrdersPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ViewedLoadedOrder> {
        const response = await this.apiViewedLoadedOrdersPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
