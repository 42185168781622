/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Driver } from './Driver';
import {
    DriverFromJSON,
    DriverFromJSONTyped,
    DriverToJSON,
} from './Driver';
import type { OfferDto } from './OfferDto';
import {
    OfferDtoFromJSON,
    OfferDtoFromJSONTyped,
    OfferDtoToJSON,
} from './OfferDto';
import type { Vehicle } from './Vehicle';
import {
    VehicleFromJSON,
    VehicleFromJSONTyped,
    VehicleToJSON,
} from './Vehicle';

/**
 * 
 * @export
 * @interface OfferResponse
 */
export interface OfferResponse {
    /**
     * 
     * @type {Driver}
     * @memberof OfferResponse
     */
    driver?: Driver;
    /**
     * 
     * @type {Vehicle}
     * @memberof OfferResponse
     */
    vehicle?: Vehicle;
    /**
     * 
     * @type {Array<OfferDto>}
     * @memberof OfferResponse
     */
    offers?: Array<OfferDto> | null;
}

/**
 * Check if a given object implements the OfferResponse interface.
 */
export function instanceOfOfferResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OfferResponseFromJSON(json: any): OfferResponse {
    return OfferResponseFromJSONTyped(json, false);
}

export function OfferResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'driver': !exists(json, 'driver') ? undefined : DriverFromJSON(json['driver']),
        'vehicle': !exists(json, 'vehicle') ? undefined : VehicleFromJSON(json['vehicle']),
        'offers': !exists(json, 'offers') ? undefined : (json['offers'] === null ? null : (json['offers'] as Array<any>).map(OfferDtoFromJSON)),
    };
}

export function OfferResponseToJSON(value?: OfferResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'driver': DriverToJSON(value.driver),
        'vehicle': VehicleToJSON(value.vehicle),
        'offers': value.offers === undefined ? undefined : (value.offers === null ? null : (value.offers as Array<any>).map(OfferDtoToJSON)),
    };
}

