/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProfileInfoDto
 */
export interface ProfileInfoDto {
    /**
     * 
     * @type {string}
     * @memberof ProfileInfoDto
     */
    displayRibbonOnProfiles?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileInfoDto
     */
    activeProfiles?: Array<string> | null;
}

/**
 * Check if a given object implements the ProfileInfoDto interface.
 */
export function instanceOfProfileInfoDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProfileInfoDtoFromJSON(json: any): ProfileInfoDto {
    return ProfileInfoDtoFromJSONTyped(json, false);
}

export function ProfileInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayRibbonOnProfiles': !exists(json, 'display-ribbon-on-profiles') ? undefined : json['display-ribbon-on-profiles'],
        'activeProfiles': !exists(json, 'activeProfiles') ? undefined : json['activeProfiles'],
    };
}

export function ProfileInfoDtoToJSON(value?: ProfileInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'display-ribbon-on-profiles': value.displayRibbonOnProfiles,
        'activeProfiles': value.activeProfiles,
    };
}

