/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EUserStatus } from './EUserStatus';
import {
    EUserStatusFromJSON,
    EUserStatusFromJSONTyped,
    EUserStatusToJSON,
} from './EUserStatus';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ApplicationUser
 */
export interface ApplicationUser {
    /**
     * 
     * @type {EUserStatus}
     * @memberof ApplicationUser
     */
    status?: EUserStatus;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationUser
     */
    lastLoginDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUser
     */
    internalUserId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof ApplicationUser
     */
    internalUser?: User;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUser
     */
    id?: number;
}

/**
 * Check if a given object implements the ApplicationUser interface.
 */
export function instanceOfApplicationUser(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApplicationUserFromJSON(json: any): ApplicationUser {
    return ApplicationUserFromJSONTyped(json, false);
}

export function ApplicationUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : EUserStatusFromJSON(json['status']),
        'lastLoginDate': !exists(json, 'lastLoginDate') ? undefined : (json['lastLoginDate'] === null ? null : new Date(json['lastLoginDate'])),
        'internalUserId': !exists(json, 'internalUserId') ? undefined : json['internalUserId'],
        'internalUser': !exists(json, 'internalUser') ? undefined : UserFromJSON(json['internalUser']),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function ApplicationUserToJSON(value?: ApplicationUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': EUserStatusToJSON(value.status),
        'lastLoginDate': value.lastLoginDate === undefined ? undefined : (value.lastLoginDate === null ? null : value.lastLoginDate.toISOString()),
        'internalUserId': value.internalUserId,
        'internalUser': UserToJSON(value.internalUser),
        'id': value.id,
    };
}

