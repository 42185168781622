/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ApiGoogleRestGetRequest {
    rest: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface ApiGoogleRestPostRequest {
    rest: string;
}

/**
 * 
 */
export class GoogleApi extends runtime.BaseAPI {

    /**
     */
    async apiGoogleRestGetRaw(requestParameters: ApiGoogleRestGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.rest === null || requestParameters.rest === undefined) {
            throw new runtime.RequiredError('rest','Required parameter requestParameters.rest was null or undefined when calling apiGoogleRestGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/google/{rest}`.replace(`{${"rest"}}`, encodeURIComponent(String(requestParameters.rest))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiGoogleRestGet(requestParameters: ApiGoogleRestGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.apiGoogleRestGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGoogleRestPostRaw(requestParameters: ApiGoogleRestPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.rest === null || requestParameters.rest === undefined) {
            throw new runtime.RequiredError('rest','Required parameter requestParameters.rest was null or undefined when calling apiGoogleRestPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/google/{rest}`.replace(`{${"rest"}}`, encodeURIComponent(String(requestParameters.rest))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiGoogleRestPost(requestParameters: ApiGoogleRestPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.apiGoogleRestPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
