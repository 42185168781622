/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LoadedOrder } from './LoadedOrder';
import {
    LoadedOrderFromJSON,
    LoadedOrderFromJSONTyped,
    LoadedOrderToJSON,
} from './LoadedOrder';
import type { Vehicle } from './Vehicle';
import {
    VehicleFromJSON,
    VehicleFromJSONTyped,
    VehicleToJSON,
} from './Vehicle';
import type { VehicleForOffer } from './VehicleForOffer';
import {
    VehicleForOfferFromJSON,
    VehicleForOfferFromJSONTyped,
    VehicleForOfferToJSON,
} from './VehicleForOffer';

/**
 * 
 * @export
 * @interface OfferDto
 */
export interface OfferDto {
    /**
     * 
     * @type {LoadedOrder}
     * @memberof OfferDto
     */
    loadedOrder?: LoadedOrder;
    /**
     * 
     * @type {Vehicle}
     * @memberof OfferDto
     */
    vehicle?: Vehicle;
    /**
     * 
     * @type {number}
     * @memberof OfferDto
     */
    vehicleMiles?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferDto
     */
    offerMiles?: number;
    /**
     * 
     * @type {Array<VehicleForOffer>}
     * @memberof OfferDto
     */
    vehicles?: Array<VehicleForOffer> | null;
}

/**
 * Check if a given object implements the OfferDto interface.
 */
export function instanceOfOfferDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OfferDtoFromJSON(json: any): OfferDto {
    return OfferDtoFromJSONTyped(json, false);
}

export function OfferDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'loadedOrder': !exists(json, 'loadedOrder') ? undefined : LoadedOrderFromJSON(json['loadedOrder']),
        'vehicle': !exists(json, 'vehicle') ? undefined : VehicleFromJSON(json['vehicle']),
        'vehicleMiles': !exists(json, 'vehicleMiles') ? undefined : json['vehicleMiles'],
        'offerMiles': !exists(json, 'offerMiles') ? undefined : json['offerMiles'],
        'vehicles': !exists(json, 'vehicles') ? undefined : (json['vehicles'] === null ? null : (json['vehicles'] as Array<any>).map(VehicleForOfferFromJSON)),
    };
}

export function OfferDtoToJSON(value?: OfferDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'loadedOrder': LoadedOrderToJSON(value.loadedOrder),
        'vehicle': VehicleToJSON(value.vehicle),
        'vehicleMiles': value.vehicleMiles,
        'offerMiles': value.offerMiles,
        'vehicles': value.vehicles === undefined ? undefined : (value.vehicles === null ? null : (value.vehicles as Array<any>).map(VehicleForOfferToJSON)),
    };
}

