/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EGreetingMessageTarget,
  GreetingMessage,
} from '../models';
import {
    EGreetingMessageTargetFromJSON,
    EGreetingMessageTargetToJSON,
    GreetingMessageFromJSON,
    GreetingMessageToJSON,
} from '../models';

export interface ApiMobileGreetingMessagesPostRequest {
    greetingMessage?: GreetingMessage;
}

export interface ApiMobileGreetingMessagesPutRequest {
    greetingMessage?: GreetingMessage;
}

export interface ApiMobileGreetingMessagesTargetGetRequest {
    target: EGreetingMessageTarget;
    count?: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

/**
 * 
 */
export class GreetingMessageApi extends runtime.BaseAPI {

    /**
     */
    async apiMobileGreetingMessagesPostRaw(requestParameters: ApiMobileGreetingMessagesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GreetingMessage>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mobile/greetingMessages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GreetingMessageToJSON(requestParameters.greetingMessage),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GreetingMessageFromJSON(jsonValue));
    }

    /**
     */
    async apiMobileGreetingMessagesPost(requestParameters: ApiMobileGreetingMessagesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GreetingMessage> {
        const response = await this.apiMobileGreetingMessagesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiMobileGreetingMessagesPutRaw(requestParameters: ApiMobileGreetingMessagesPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GreetingMessage>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mobile/greetingMessages`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GreetingMessageToJSON(requestParameters.greetingMessage),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GreetingMessageFromJSON(jsonValue));
    }

    /**
     */
    async apiMobileGreetingMessagesPut(requestParameters: ApiMobileGreetingMessagesPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GreetingMessage> {
        const response = await this.apiMobileGreetingMessagesPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiMobileGreetingMessagesTargetGetRaw(requestParameters: ApiMobileGreetingMessagesTargetGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GreetingMessage>>> {
        if (requestParameters.target === null || requestParameters.target === undefined) {
            throw new runtime.RequiredError('target','Required parameter requestParameters.target was null or undefined when calling apiMobileGreetingMessagesTargetGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/mobile/greetingMessages/{target}`.replace(`{${"target"}}`, encodeURIComponent(String(requestParameters.target))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GreetingMessageFromJSON));
    }

    /**
     */
    async apiMobileGreetingMessagesTargetGet(requestParameters: ApiMobileGreetingMessagesTargetGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GreetingMessage>> {
        const response = await this.apiMobileGreetingMessagesTargetGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
