/* tslint:disable */
/* eslint-disable */
/**
 * UrsaLogistics API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface MobileSettings
 */
export interface MobileSettings {
    /**
     * 
     * @type {number}
     * @memberof MobileSettings
     */
    radius?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MobileSettings
     */
    userId?: string | null;
    /**
     * 
     * @type {User}
     * @memberof MobileSettings
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof MobileSettings
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MobileSettings
     */
    createdDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MobileSettings
     */
    lastModifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MobileSettings
     */
    lastModifiedDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof MobileSettings
     */
    id?: number;
}

/**
 * Check if a given object implements the MobileSettings interface.
 */
export function instanceOfMobileSettings(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MobileSettingsFromJSON(json: any): MobileSettings {
    return MobileSettingsFromJSONTyped(json, false);
}

export function MobileSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'radius': !exists(json, 'radius') ? undefined : json['radius'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (json['createdDate'] === null ? null : new Date(json['createdDate'])),
        'lastModifiedBy': !exists(json, 'lastModifiedBy') ? undefined : json['lastModifiedBy'],
        'lastModifiedDate': !exists(json, 'lastModifiedDate') ? undefined : (json['lastModifiedDate'] === null ? null : new Date(json['lastModifiedDate'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function MobileSettingsToJSON(value?: MobileSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'radius': value.radius,
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate === null ? null : value.createdDate.toISOString()),
        'lastModifiedBy': value.lastModifiedBy,
        'lastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate === null ? null : value.lastModifiedDate.toISOString()),
        'id': value.id,
    };
}

